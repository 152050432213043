import React from 'react';
import Layout from '../components/layout/Layout';

const NotFound = () => (
  <Layout>
    <h1>Não encontrada</h1>
    <p>Esta página que você está buscando não existe...</p>
  </Layout>
);

export default NotFound;
